<template>
  <div class="slider">
    <div class="slider-range" :class="rangeStatus?'success':''">
      <span class="icon" @mousedown="rangeMove" :class="rangeStatus?'successIcon':'icon'"></span>
      <span>{{rangeStatus?successText:startText}}</span>
    </div>
  </div>
</template>

<script>
export default {
// 滑块验证
  name: 'slider',
  props: {
    // 成功之后的函数
    successFun: {
      type: Function
    },
    // 成功图标
    successIcon: {
      type: String,
      default: 'check-circle'
    },
    // 成功文字
    successText: {
      type: String,
      default: '验证成功'
    },
    // 开始的图标
    startIcon: {
      type: String,
      default: 'double-right'
    },
    // 开始的文字
    startText: {
      type: String,
      default: '按住滑块滑动，拖到最右边'
    },
    // 失败之后的函数
    errorFun: {
      type: Function
    },
    // 或者用值来进行监听
    status: {
      type: String
    }
  },
  data () {
    return {
      disX: 0,
      rangeStatus: false
    }
  },
  methods: {
    // 滑块移动
    rangeMove (e) {
      const ele = e.target
      const startX = e.clientX
      const eleWidth = ele.offsetWidth
      const parentWidth = ele.parentElement.offsetWidth
      const MaxX = parentWidth - eleWidth
      if (this.rangeStatus) { // 不运行
        return false
      }
      document.onmousemove = (e) => {
        const endX = e.clientX
        this.disX = endX - startX
        if (this.disX <= 0) {
          this.disX = 0
        }
        if (this.disX >= MaxX - eleWidth) { // 减去滑块的宽度,体验效果更好
          this.disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + this.disX + 'px)'
        e.preventDefault()
        // e.stopPropagation()
      }
      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          // 执行成功的函数
          this.errorFun && this.errorFun(false)
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          // 执行成功的函数
          this.successFun && this.successFun(true)
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="less" scoped="scoped">
.slider {
	width: 368px;
	border-radius: 5px;
	overflow: hidden;
  .slider-range {
    background-color: #F5F5F5;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #585858;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    .icon{
      position: absolute;
      left: 0;
      width: 52px;
      height: 40px;
      cursor: pointer;
      background: url(https://shunong.oss-cn-shenzhen.aliyuncs.com/digital/digital202109022443715084120030.png) no-repeat;
      background-position: center;
      background-size: contain;
    }
    .successIcon {
      background: url(https://shunong.oss-cn-shenzhen.aliyuncs.com/digital/digital202109029246378929120145.png) no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .success{
    background-color: #3bc923;
    color: #fff;
    i{
      color: #3bc923;
    }
  }
}
</style>
