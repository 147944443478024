<template class="layout">
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" class="menusider" :trigger="null" collapsible>
      <div class="logo" v-if="!collapsed" />
      <div class="logo-d" v-else />
      <keep-alive>
        <a-menu theme="dark" mode="inline" :openKeys.sync="openKeys" :selectedKeys="selectedKeys" v-model="selectedKeys"
          @openChange="onOpenChange">
          <template v-for="(item) in menuList">
            <template v-if="item.meta.show">
              <template v-if="!item.meta.showChilder">
                <a-menu-item :key="`${item.meta.key}`">
                  <a-icon :type="item.meta.icon" />
                  <span @click="accessUrl(item)">{{ item.meta.title }}</span>
                </a-menu-item>
              </template>
              <template v-else>
                <a-sub-menu :key="`${item.meta.key}`" @titleClick="(e, domEvent) => { titleClick(item, e) }">
                  <span slot="title"><a-icon :type="item.meta.icon" /><span>{{ item.meta.title }}</span></span>
                  <template v-for="(items) in item.children">
                    <a-menu-item :key="`${items.meta.key}`" v-if="items.meta.show" @click="accessUrl(items)">
                      {{ items.meta.title }}
                    </a-menu-item>
                  </template>
                </a-sub-menu>
              </template>
            </template>
          </template>
        </a-menu>
      </keep-alive>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)" />
        <div class="user-wrapper">
          剩余{{ count }}点能量值
          <div class="content-box">
            <div>
              <a-dropdown>
                <div class="ant-dropdown-link avatarnickname">
                  <img class="useravatar" src="~@/assets/img/user.png"><span>{{ userInfo.username }}</span><a-icon
                    class="downtips" type="down" />
                </div>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <div @click="toSet()" class="dowmenuitem"><a-icon type="setting" class="mgl-20" /><span
                        class="mgl-10">设置</span></div>
                  </a-menu-item>
                  <a-menu-item>
                    <div @click="exit()" class="dowmenuitem"><a-icon type="logout" class="mgl-20" /><span
                        class="mgl-10">退出登录</span></div>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </div>
      </a-layout-header>
      <div class="breadCrumbs">
        <a-breadcrumb>
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, num) in $route.meta.readcrumb" :key="num"><router-link :to="item.url">{{
      item.name }}</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>{{ $route.meta.title }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <a-layout-content :style="{ padding: '24px' }">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { routes } from '../../router/index'
import { logoutUser, ApiUserInfo, getCount1 } from '@/api/login'
export default {
  data() {
    return {
      collapsed: false,
      menuList: [],
      openKeys: ['1'],
      selectedKeys: ['1'],
      userInfo: {},
      count: 0
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.specifyMenu = val.meta.key
        // this.openKeys = this.openKeys.push(`${val.meta.parent}`)
        this.setMenu(val)
      },
      immediate: true
    }
  },
  mounted() {
    // this.getApiLoadNav()
    this.menuList = routes[2].children
    // this.setMenu()
    this.getUserInfo()
  },
  methods: {
    setMenu(val) {
      console.log(this.menuList)
      // 暂时不需要 动态路由
      this.selectedKeys = [`${val.meta.key}`]
      let key = `${val.meta.parent ? val.meta.parent : val.meta.key}`
      this.onOpenChange([`${key}`])
    },
    getUserInfo() {
      ApiUserInfo().then(res => {
        if (res.code === 0) {
          this.$store.dispatch('set_userInfo', JSON.stringify(res.data))
          this.$store.dispatch('set_nickName', res.data.name)
          this.userInfo = this.$store.state.userInfo ? JSON.parse(this.$store.state.userInfo) : JSON.parse(localStorage.getItem('userInfo'))
          this.getCount()
        }
      })
    },
    getCount() {
      getCount1().then(res => {
        if (res.code === 0) {
          this.count = res.data.accountBalance
        }
      })
    },
    exit() {
      logoutUser().then(res => {
        if (res.code === 0) {
          window.localStorage.removeItem('userInfo')
          window.localStorage.removeItem('nickName')
          window.localStorage.removeItem('token')
          this.$message.success('退出成功')
          this.$store.dispatch('set_token', '')
          this.$router.push({ name: 'Login' })
          window.location.reload()
        }
      })
    },
    onOpenChange(openKeys) {
      this.openKeys = openKeys
    },
    titleClick(item, e, domEvent) {
      let key = item.children[0].meta.key
      this.selectedKeys = [`${key}`]
      if (this.$route.name != item.children[0].name) {
        this.$router.push({
          name: item.children[0].name
        })
      }
    },
    accessUrl(item) {
      this.selectedKeys = [`${item.meta.key}`]
      this.$router.push({
        path: item.path
      })
    },
    toSet() {
      this.$router.push({
        name: 'myCenter'
      })
    }
  }
}
</script>
<style>
#components-layout-demo-custom-trigger {
  width: 100vw;
  height: 100vh;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  background: url('../../assets/logo.png') center no-repeat;
  background-size: 80%;
}

.logo-d {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  background: url('../../assets/logo-d.png') center no-repeat;
  background-size: 60%;
}

.user-wrapper {
  float: right;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.useravatar {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.avatarnickname {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  cursor: pointer;
}

.downtips {
  color: #ccc;
  font-size: 14px;
  margin-left: 6px;
  padding-top: 2px;
  transition: all .32s cubic-bezier(.24, .82, .48, .89);
}

.user-wrapper:hover .downtips {
  transform: rotate(180deg);
  transition: all .32s cubic-bezier(.24, .82, .48, .89);
}

.mgl-10 {
  margin-left: 10px;
}

.mgl-20 {
  margin-left: 20px;
}

.dowmenuitem:hover {
  color: #1890ff;
}

.breadCrumbs {
  background: #ffffff;
  text-align: left;
  margin-top: 1px;
  padding: 16px 32px;
}
</style>
