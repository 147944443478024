import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: void(0),
    nickName: void(0),
    userInfo: void(0)
  },
  getters: {
  },
  mutations: {
    set_token(state, val) {
      console.log(state, val)
      state.token = val
      localStorage.setItem('token', val)
    },
    set_nickName(state, val) {
      state.nickName = val
      localStorage.setItem('nickName', val)
    },
    set_userInfo(state, val) {
      state.userInfo = val
      localStorage.setItem('userInfo', val)
    }
  },
  actions: {
    set_token(context, val) {
      context.commit('set_token', val)
    },
    set_nickName(context, val) {
      context.commit('set_nickName', val)
    },
    set_userInfo(context, val) {
      context.commit('set_userInfo', val)
    }
  },
  modules: {
  }
})
