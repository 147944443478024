import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import less from 'less'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import 'ant-design-vue/dist/antd.less'
import '../src/assets/css/wap.css'

Vue.config.productionTip = false

if (store.state.token || localStorage.getItem('token')) {
  let token = store.state.token || localStorage.getItem('token')
  store.commit('set_token', token)
}

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    let tit = document.title
    if (tit.includes('—')) {
      tit = tit.substring(0, tit.indexOf('—'))
    }
    document.title = tit + '—' + to.meta.title
  }
  const token = store.state.token || localStorage.getItem('token')
  if (to.name == 'Login' || to.name == 'home') {
    if (!token) {
      next()
    } else {
      router.push({ name: 'workbench' })
    }
  } else {
    if (token) {
      next()
    } else {
      router.push({ name: 'Login' })
    }
  }
})

Vue.use(Antd, less, Viewer)
Viewer.setDefaults({
  Options: { movable: true, zoomable: true }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
