<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>
<style scoped='scoped' lang='less'>
/deep/ .ant-btn{
  height: 40px;
}
/deep/ .ant-btn-primary {
    color: #fff;background-color: #449897;
    border-color: #449897;
}
/deep/ .ant-btn-primary:hover {
    color: #fff;
    background-color: #387e7d;
    border-color: #387e7d;
}
/deep/ .ant-btn-primary:focus {
    color: #fff;
    background-color: #449897 !important;
    border-color: #449897 !important;
}
/deep/ .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
background-color: #449897;
}
/deep/ .ant-card .ant-btn{
    height: 32px;
    margin: 0 4px;
    padding: 0 20px;
}
/deep/.panel{
  display: flex;
  align-items: center;
  height: 40px;
}
/deep/ .ant-card{
  margin-bottom: 16px;
  border-radius: 4px;
}
/deep/ .pagination{
  text-align: right;
  margin:28px 0 0 0;
}
/deep/ .ant-input{
  width: 100%;
}
/deep/ .ant-btn-background-ghost.ant-btn-primary {
  color: #0DBC79;
  background-color: transparent;
  border-color: #0DBC79;
  text-shadow: none;
}
/deep/ .ant-btn-background-ghost.ant-btn-primary:hover {
  color: #fff;
  background: #0DBC79 !important;
  border-color: #0DBC79;
  text-shadow: none;
}
/deep/ .ant-btn-background-ghost .anticon{
  font-size: 18px;
  line-height: 2;
  vertical-align: -0.25em;
}
</style>
