export function setLocalStorage(key, value, is) {
  let curTime = new Date().getTime()
  let data = localStorage.getItem(key)
  if (!data) {
    localStorage.setItem(key, JSON.stringify({ data: value, time: curTime, auto: is }))
  } else {
    localStorage.setItem(key, JSON.stringify({ data: value, time: data.time, auto: is }))
  }
}
export function getLocalStorage(key, exp) {
  let data = localStorage.getItem(key)
  if (!data) {
    return false
  }
  let dataObj = JSON.parse(data)
  // let exp =
  if (new Date().getTime() - dataObj.time > exp) {
    localStorage.removeItem(key)
    return false
  }
  return dataObj.data
}
