import { render, staticRenderFns } from "./slider.vue?vue&type=template&id=0164baa7&scoped=true"
import script from "./slider.vue?vue&type=script&lang=js"
export * from "./slider.vue?vue&type=script&lang=js"
import style0 from "./slider.vue?vue&type=style&index=0&id=0164baa7&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0164baa7",
  null
  
)

export default component.exports