<template>
  <div class='login'>
    <div class='login-content'>
      <div class='login-content-left'></div>
      <div class='login-content-right'>
        <div class='login-content-right-form'>
          <div class='login-content-right-form-title'>阅合管理后台</div>
          <div class='login-content-right-form-info'>
            <a-form-model :rules="rules" :model="form" ref="formInfo" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item prop="username">
                <a-input v-model="form.username" placeholder="请输入用户名" class="wform-form">
                  <a-icon slot="prefix" type="user" class="set-color" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="password">
                <a-input v-model="form.password" placeholder="请输入用密码" class="wform-form" type="password">
                  <a-icon slot="prefix" type="lock" class="set-color" />
                </a-input>
              </a-form-model-item>
              <a-form-model-item prop="verify">
                <a-input v-model="form.verify" placeholder="请输入用验证码" class="wform-form" style="width:70%;">
                  <a-icon slot="prefix" type="security-scan" class="set-color" />
                </a-input>
                <img :src="imgKey" @click="updateKey" :key="imgKey" class="verify" />
              </a-form-model-item>
              <a-form-model-item style="margin-bottom:0">
                <slider :successFun='handleSuccessFun'></slider>
                <div slot="extra" class="tips">
                  {{ slidMsg }}
                </div>
              </a-form-model-item>
              <a-form-model-item>
                <a-checkbox :checked="record" @change="recordChange">记住密码</a-checkbox>
              </a-form-model-item>
            </a-form-model>
            <a-button block @click="handleForm">登录</a-button>
            <!-- 	<div slot='forget' class='forget' ><span @click='handleForget'>忘记密码</span></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import slider from '@/components/slider/slider'
import { decrypt, encrypt } from '@/utils/encryption'
import { setLocalStorage, getLocalStorage } from '@/utils/storage'
import { loginUser } from '@/api/login'
export default {
  name: 'login',
  components: {
    slider
  },
  data() {
    return {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 },
      imgKey: '',
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'change' }
        ],
        verify: [
          { required: true, message: '请输入验证码', trigger: 'change' }
        ]
      },
      record: false,
      status: false,
      slidMsg: '',
      history: '',
      noncer: ''
    }
  },
  created() {
    this.noncer = new Date().getTime()
    this.imgKey = `https://h5admin.ctippro.com/v1/login/captcha.jpg?noncer=${this.noncer}`
  },
  mounted() {
    let rt = getLocalStorage('ciphertext', 86400000 * 7)
    let ischeck = JSON.parse(localStorage.getItem('ciphertext')).auto || false
    let res = rt ? decrypt(rt) : ''
    if (res && ischeck) {
      this.history = res
      this.record = true
      let { use, pwd } = JSON.parse(res)
      this.form.username = use
      this.form.password = pwd
    }
  },
  methods: {
    // 滑块验证成功回调
    handleSuccessFun(e) {
      this.status = e
    },
    recordChange(e) {
      this.record = e.target.checked
    },
    // 操作登录按钮
    handleForm(item) {
      this.$refs.formInfo.validate(valid => {
        if (valid) {
          if (!this.status) {
            this.slidMsg = '请拖动滑块待验证后登录系统'
          } else {
            if (this.history) {
              let { use, pwd } = JSON.parse(this.history)
              if (this.form.username !== use || this.form.password !== pwd) {
                let ciphertext = encrypt({ use: this.form.username, pwd: this.form.password })
                setLocalStorage('ciphertext', ciphertext, this.record)
              }
              let ciphertext = encrypt({ use: this.form.username, pwd: this.form.password })
              setLocalStorage('ciphertext', ciphertext, this.record)
            } else {
              let ciphertext = encrypt({ use: this.form.username, pwd: this.form.password })
              setLocalStorage('ciphertext', ciphertext, this.record)
            }
            this.login()
          }
        }
      })
    },
    updateKey() {
      this.noncer = new Date().getTime()
      this.imgKey = `https://h5admin.ctippro.com/v1/login/captcha.jpg?noncer=${this.noncer}`
    },
    login() {
      const CryptoJS = require('crypto-js')
      const params = {
        acc: this.form.username,
        pass: CryptoJS.MD5(this.form.password).toString(),
        verify: this.form.verify,
        noncer: this.noncer
      }
      loginUser(params).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.$store.dispatch('set_token', res.data)
          this.$message.success('登录成功')
          this.$router.push({ path: '/workbench' })
        }
      })
    }
  }
}
</script>

<style scoped='scoped' lang='less'>
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  background: url(https://shunong.oss-cn-shenzhen.aliyuncs.com/image/2021080391608020210803220300.png) no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f0f3f7;

  /deep/.wform-form .ant-form-item {
    padding: 0;
  }

  /deep/.wform-form .ant-input {
    height: 40px;
  }

  /deep/.wform-info-footer button {
    font-size: 16px;
    height: 40px;
    margin: 0;
    padding: 0 20px;
    width: 368px;
  }

  &-content {
    display: flex;
    width: 820px;
    height: 500px;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 43px;
    padding-right: 56px;

    &-left {
      width: 336px;
      height: 206px;
      background: url(https://shunong.oss-cn-shenzhen.aliyuncs.com/image/2021080336098020210803174612.png) no-repeat;
      background-position: center;
      background-size: 336px 206px;
      margin-top: 147px;
    }

    &-right {
      width: 385px;
      height: 500px;
      padding-left: 17px;

      &-form {
        width: 368px;

        &-title {
          width: 288px;
          // height: 52px;
          font-size: 32px;
          font-weight: 500;
          color: #333333;
          line-height: 52px;
          text-align: center;
          margin: 0 auto;
          margin-top: 32px;
          margin-bottom: 24px;
        }

        .forget {
          font-size: 14px;
          color: #1890ff;
          cursor: pointer;
          text-align: right;
          margin-top: -57px;
        }
      }
    }

    .login-content-right-form-title {
      white-space: nowrap;
    }
  }
}

.set-color {
  opacity: 0.5;
}

.set-line {
  padding: 20px 0;
}

/deep/ .ant-btn {
  height: 40px;
}

.tips {
  color: #f5222d;
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  font-size: 14px;
  line-height: 1.5;
  transition: color .3s cubic-bezier(.215, .61, .355, 1);
}

.verify {
  height: 40px;
  float: right;
  margin-right: 10px;
}
</style>
