import Vue from 'vue'
import VueRouter from 'vue-router'
import WorkbenchView from '../views/workbench/index'
import LoginView from '../views/login/index'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
    redirect: { name: 'Login' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index')
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: WorkbenchView,
    redirect: { name: 'artwork' },
    children: [
      { // 藏品管理
        path: '/artwork',
        name: 'artwork',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '藏品管理', key: 1, icon: 'save', show: true, showChilder: true },
        redirect: { name: 'artworkpool' },
        children: [
          {
            path: '/artwork/pool',
            name: 'artworkpool',
            component: () => import('../views/artwork/activity/artwork'),
            meta: { title: '藏品列表', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: true }
          },
          {
            path: '/artwork/detail',
            name: 'artworkDetail',
            component: () => import('../views/artwork/artworkDetail'),
            meta: { title: '商品详情', key: 11, parent: 11, readcrumb: [{ name: '商品管理', url: '/merchandise/list' }], show: false }
          },
          {
            path: '/artwork/addSingle',
            name: 'addSingle',
            component: () => import('../views/artwork/addCollection'),
            meta: { title: '新增数字藏品', key: 11, parent: 1, readcrumb: [{ name: '商品管理', url: '/artwork' }], show: false }
          },
          {
            path: '/artwork/editSingle',
            name: 'editSingle',
            component: () => import('../views/artwork/editCollection'),
            meta: { title: '编辑数字藏品', key: 11, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork' }], show: false }
          },
          {
            path: '/artwork/addPool',
            name: 'addPool',
            component: () => import('../views/artwork/activity/add'),
            meta: { title: '新增藏品', key: 15, parent: 1, readcrumb: [{ name: '新增藏品', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/poolEdit',
            name: 'poolEdit',
            component: () => import('../views/artwork/activity/edit'),
            meta: { title: '编辑藏品', key: 15, parent: 1, readcrumb: [{ name: '编辑藏品', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/artWarePool',
            name: 'artWarePool',
            component: () => import('../views/artwork/activity/artWare'),
            meta: { title: '藏品详情', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/artWarePoolPutIn',
            name: 'artWarePoolPutIn',
            component: () => import('../views/artwork/collectionLaunch'),
            meta: { title: '藏品投放', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/artPutIn',
            name: 'artPutIn',
            component: () => import('../views/artwork/collectionLaunch'),
            meta: { title: '藏品投放', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/artPutRecord',
            name: 'artPutRecord',
            component: () => import('../views/whiteList/putRecord.vue'),
            meta: { title: '藏品投放记录', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: false }
          },
          {
            path: '/artwork/poolDetails',
            name: 'poolDetails',
            component: () => import('../views/artwork/activity/details'),
            meta: { title: '藏品详情', key: 15, parent: 1, readcrumb: [{ name: '藏品管理', url: '/artwork/pool' }], show: false }
          }
          // {
          //   path: '/artwork/addSeries',
          //   name: 'addSeries',
          //   component: () => import('../views/artwork/addCollection'),
          //   meta: { title: '新增系列数字藏品', key: 13, readcrumb: [{ name: '藏品管理', url: '/artwork' }], show: true }
          // }
        ]
      },
      {
        path: '/merchandise/list',
        name: 'merchandise',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '商品管理', key: 2, icon: 'shopping-cart', show: true, showChilder: true },
        redirect: { name: 'whiteList' },
        children: [
          {
            path: '/merchandise/list',
            name: 'merchandiseList',
            component: () => import('../views/artwork/artworkList'),
            meta: { title: '藏品售卖列表', key: 11, parent: 2, readcrumb: [{ name: '商品管理', url: '/merchandise/list' }], show: true }
          }
        ]
      },
      {
        path: '/transfer',
        name: 'transfer',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '转赠管理', key: 55, icon: 'gift', show: true, showChilder: true },
        redirect: { name: 'transfer' },
        children: [
          {
            path: '/transfer/list',
            name: 'transfer',
            component: () => import('../views/transfer'),
            meta: { title: '转赠列表', key: 123, parent: 55, readcrumb: [{ name: '转赠管理', url: '/transfer' }], show: true }
          }
        ]
      },
      {
        path: '/artwork/white',
        name: 'white',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '优先购名单列表', key: 5, parent: 1, readcrumb: [], show: false },
        redirect: { name: 'whiteList' },
        children: [
          {
            path: '/artwork/whiteList',
            name: 'whiteList',
            component: () => import('../views/whiteList/list'),
            meta: { title: '投放白名单', key: 51, parent: 1, readcrumb: [], show: false }
          }, {
            path: '/artwork/firstBuyList',
            name: 'firstBuyList',
            component: () => import('../views/artwork/whiteList/list'),
            meta: { title: '优先购名单列表', key: 521, parent: 1, readcrumb: [], show: false }
          }
        ]
      },
      // {
      //   path: '/activity',
      //   name: 'activity',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
      //   meta: { title: '活动管理', key: 7, icon: 'deployment-unit', show: true, showChilder: true },
      //   redirect: { name: 'activityList' },
      //   children: [
      //     {
      //       path: '/activity/list',
      //       name: 'activityList',
      //       component: () => import('../views/activity/activityList'),
      //       meta: { title: '活动列表', key: 71, parent: 7, readcrumb: [{ name: '活动管理', url: '/activity' }], show: true }
      //     },
      //     {
      //       path: '/activity/blindBox',
      //       name: 'activityBlindBox',
      //       component: () => import('../views/activity/addBlindBox'),
      //       meta: { title: '添加·盲盒', key: 71, parent: 7, readcrumb: [{ name: '活动管理', url: '/activity' }], show: false }
      //     },
      //     {
      //       path: '/activity/blindBoxPut',
      //       name: 'blindBoxPut',
      //       component: () => import('../views/activity/blindBox/putInList'),
      //       meta: { title: '盲盒开启记录', key: 71, parent: 7, readcrumb: [{ name: '活动管理', url: '/activity' }], show: false }
      //     },
      //     {
      //       path: '/activity/details',
      //       name: 'activityDetails',
      //       component: () => import('../views/activity/details'),
      //       meta: { title: '活动详情', key: 71, parent: 7, readcrumb: [{ name: '活动管理', url: '/activity' }], show: false }
      //     }
      //   ]
      // },
      {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '订单管理', key: 4, icon: 'file-search', show: true, showChilder: true },
        redirect: { name: 'orderList' },
        children: [
          {
            path: '/order/list',
            name: 'orderList',
            component: () => import('../views/order/orderList'),
            meta: { title: '平台订单', key: 41, parent: 4, readcrumb: [{ name: '订单管理', url: '/order' }], show: true }
          },
          {
            path: '/order/detail',
            name: 'orderDetail',
            component: () => import('../views/order/orderDetail'),
            meta: { title: '订单详情', key: 41, parent: 4, readcrumb: [{ name: '订单管理', url: '/order' }], show: false }
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/workbench/webView'),
        meta: { title: '用户管理', key: 6, icon: 'team', show: true, showChilder: true },
        redirect: { name: 'userList' },
        children: [
          {
            path: '/user/list',
            name: 'userList',
            component: () => import('../views/user/userList'),
            meta: { title: '用户列表', key: 61, parent: 6, readcrumb: [{ name: '用户管理', url: '/user' }], show: true }
          },
          {
            path: '/user/detail',
            name: 'userDetail',
            component: () => import('../views/user/userDetail'),
            meta: { title: '用户详情', key: 61, parent: 6, readcrumb: [{ name: '用户管理', url: '/user' }], show: false }
          }
        ]
      },
      {
        path: '/mycenter',
        name: 'myCenter',
        component: () => import('../views/system/mycenter'),
        meta: { title: '账户设置', key: 3, icon: 'solution', show: true, showChilder: false }
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '公告管理', key: 20, icon: 'notification', show: true, showChilder: true },
        children: [
          {
            path: '/notice/banner',
            name: 'banner',
            component: () => import('../views/system/banner'),
            meta: { title: '轮播图管理', key: 21, parent: 20, show: true }
          }
        ]
      },
      {
        path: '/system',
        name: 'system',
        component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
        meta: { title: '系统管理', key: 33, icon: 'cluster', show: true, showChilder: true },
        children: [
          {
            path: '/system/feedback',
            name: 'feedback',
            component: () => import('../views/system/feedback'),
            meta: { title: '用户反馈', key: 34, parent: 33, show: true }
          },
          {
            path: '/system/version',
            name: 'version',
            component: () => import('../views/system/version'),
            meta: { title: '版本管理', key: 35, parent: 33, show: true }
          }
        ]
      }
      // {
      //   path: '/systemManagement',
      //   name: 'systemManagement',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/workbench/webView'),
      //   meta: { title: '系统管理', key: 9, icon: 'cluster', show: false, showChilder: true },
      //   redirect: { name: 'systemUserList' },
      //   children: [
      //     {
      //       path: '/system/user',
      //       name: 'systemUserList',
      //       component: () => import('../views/system/userList'),
      //       meta: { title: '系统账号管理', key: 91, parent: 9, readcrumb: [{ name: '系统管理', url: '/system/user' }], show: true }
      //     },
      //     {
      //       path: '/system/userDetail',
      //       name: 'systemUserDetail',
      //       component: () => import('../views/system/userinfo'),
      //       meta: { title: '用户详情', key: 91, parent: 9, readcrumb: [{ name: '系统管理', url: '/system/user' }], show: false }
      //     },
      //     {
      //       path: '/system/role',
      //       name: 'roleList',
      //       component: () => import('../views/system/roleList'),
      //       meta: { title: '角色管理', key: 92, parent: 9, readcrumb: [{ name: '系统管理', url: '/system/user' }], show: true }
      //     },
      //     {
      //       path: '/system/roleAdd',
      //       name: 'roleAdd',
      //       component: () => import('../views/system/roleAdd'),
      //       meta: { title: '添加角色', key: 92, parent: 9, readcrumb: [{ name: '系统管理', url: '/system/user' }, { name: '角色管理', url: '/system/role' }], show: true }
      //     }
      //   ]
      // }

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

if (localStorage.getItem('token') && store.state.token == '') {
  store.commit('set_token', localStorage.getItem('token'))
}

export { router, routes }
