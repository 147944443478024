import service from '@/api/config'
// 登入
export function loginUser(data) {
  return service({
    url: '/v1/login/on',
    method: 'post',
    data: data
  })
}
// 登出
export function logoutUser(data) {
  return service({
    url: '/v1/login/out',
    method: 'post',
    data: data
  })
}
// 用户信息
export function ApiUserInfo(data) {
  return service({
    url: '/v1/admin/curinfo',
    method: 'post',
    data: data
  })
}
// 余额
export function getCount1(data) {
  return service({
    url: '/v2/user/userBalance',
    method: 'post',
    data: data
  })
}
