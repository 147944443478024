import axios from 'axios'
import QS from 'qs'
import notification from 'ant-design-vue/es/notification'
import store from '@/store/index'
import { encrypt } from '@/utils/encryption'
import md5 from 'js-md5'

let baseURL = ''

if (process.env.NODE_ENV == 'development') {
  baseURL = '/api'
} else if (process.env.NODE_ENV == 'debug') {
  baseURL = '/api'
} else if (process.env.NODE_ENV == 'production') {
  baseURL = '/'
}
const service = axios.create()
// 发送 时候加参数
service.interceptors.request.use(
  config => {
    config.baseURL = baseURL
    config.headers = {
      'Content-Type': config.type ? config.type : 'application/json;charset=utf-8'
    }
    const token = store.state.token || localStorage.getItem('token')
    if (token && config.format == 'json') {
      config.headers = {
        authorization: token,
        'Content-Type': config.type ? config.type : 'application/json;charset=utf-8'
      }
    }
    if (config.method == 'get') {
      config.url = `${config.url}?${QS.stringify(config.data)}`
    }
    // if (config.format !== 'json') {
    //   if (config.action !== 'upload') {
    //     let data = new FormData()
    //     for (const key in config.data) {
    //       if (config.data[key] !== undefined && config.data[key] !== null) {
    //         data.append(key, config.data[key])
    //       }
    //     }
    //     config.data = data
    //   }
    // }
    let hexDigits = '0123456789zaqwebcdef'
    let s = []
    let k = []
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4'
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    k[14] = '6'
    k[19] = hexDigits.substr((k[19] & 0x3) | 0x8, 1)
    config.headers['X-Auth-Token'] = encrypt(s.join(''))
    config.headers['A-Auth-Token'] = encrypt(k.join(''))
    config.headers['B-Auth-Token'] = token || 'b2dc016edd8a8bd45e5ae0c88b58d63a'
    if (config.action !== 'upload') {
      let sign = QS.stringify(config.data)
      config.data = {
        ...config.data,
        t: Date.now(),
        s: md5(sign + `&${token || 'b2dc016edd8a8bd45e5ae0c88b58d63a'}`)
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 接收时 全局统一处理
service.interceptors.response.use(
  config => {
    const res = config.data
    const { code, msg } = res
    if (code != 0 && msg) {
      notification.warning({
        message: msg,
        icon: (h) => h('a-icon', {
          style: 'color:#FF2626',
          props: {
            type: 'frown'
          }
        })
      })
    }
    if (code == '100') {
      window.localStorage.clear()
      window.location.href = '/'
      return void(0)
    }
    return res
  },
  error => {
    if (!error) {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            notification.warning({
              message: '提示',
              description: '账号资源已变更或登录失效，请重新登录'
            })
            window.localStorage.clear()
            window.location.href = '/'
            break
          case 403:
            window.location.href = '/403'
            break
          case 500:
            window.location.href = '/500'
            break
          default:
            window.localStorage.removeItem('userInfo')
            window.localStorage.removeItem('nickName')
            window.localStorage.removeItem('token')
            window.location.href = '/'
            notification.warning({
              message: error.response.data.error,
              description: error.response.data.message,
              icon: (h) => h('a-icon', {
                style: 'color:#FF2626',
                props: {
                  type: 'frown'
                }
              })
            })
            return error.response
        }
      }
    }
  }
)

export default service
